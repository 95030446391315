.rounded-header {
  border: 1px solid #a3a2a2;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom: none 0 transparent;
  text-align: center;
  background-color: #a3a2a2;
}

.second-header {
  border-left: 1px solid #a3a2a2;
  border-right: 1px solid #a3a2a2;
  background-color: #a3a2a2;
  font-weight: 900;
  text-align: center;
}

td.subheader {
  color: white;
  background-color: #a3a2a2;
  text-align: center;
  font-size: 12px;
}

.top-area {
  padding: 10px;
  border: solid 1px #cacaca;
  margin-top: 5px;
  margin-bottom: 20px;
}

.number {
  text-align: right;
  padding-right: 5px;
  border-right: solid 1px #a3a2a2;
}

.right-align {
  text-align: right;
}

.red {
  color: red;
}

.border-right {
  border-right: solid 1px #a3a2a2;
}

.border-left {
  border-left: solid 1px #a3a2a2;
}

.period {
  padding: 5px;
  border-left: solid 1px #a3a2a2;
  border-bottom: solid 1px #a3a2a2;
}

.period-bottom > td {
  border-bottom: solid 1px #a3a2a2;
}

td.gap {
  border-bottom: none;
}

.period-summary {
  display: inline-block;
}
